import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card, Media } from "react-bootstrap"

import Layout from "../../components/layout"
// import SEO from "../../components/seo"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

import PartnerSlider from "../../components/partnerSlider"

class Reseller extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Global Reseller Partner Network"}
          titleDesc={
            "Grow with us! Join Our Global Reseller Partner Network and get all the benefits and milestone rewards along with it."
          }
          titleKeywords={
            "fuse classroom, enterprise software, software reseller opportunities, reselling programs, software reseller, reseller business opportunities, reseller program, reseller opportunities, reseller partner program"
          }
          fbDes={"Join our Global Reseller Partner Network and grow with us."}
          twitDesc={"Join our Global Reseller Partner Network and grow with us."}
          seoLink={"partners/reseller/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green">
          {/* content */}
          <article className="container">
            <Row className="justify-content-center py-4">
              <Col md="10" className="pt-md-5 pb-5 text-center">
                <div className="text-green">
                  <h1>Join Our Global Reseller Partner Network</h1>
                  <div className="h5 my-4 text-black font-weight-normal">
                    Join the global reseller partner network for Fuse Classroom. We’re actively seeking anyone with
                    enterprise software selling experience and network. Our reseller program provides benefits, such as
                    tiered compensation, scaling revenue share percentages, and milestone rewards. Apply to join using
                    the form below, we can’t wait to work with you.
                  </div>
                  <a
                    href="https://docs.google.com/forms/d/1tyt1ETTIJu4tk6hARl4pPggd-fYTmUL6H7pXFaEDXOk/viewform?edit_requested=true"
                    target="_blank"
                    className="btn btn-warning btn-xl"
                    id="apply-now__top"
                  >
                    APPLY NOW
                  </a>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={{ span: 10, order: 2 }} className="text-center my-4">
                <img src="/images/partners/map.webp" alt="Map" className="img-fluid" width="500" height="auto" />
              </Col>

              <Col md={{ span: 2, offset: 2 }}>
                <div className="partner-country">
                  <div className="place">
                    <img
                      src="/images/partners/america.svg"
                      alt="America"
                      className="mr-2 img-fluid"
                      width="35"
                      height="auto"
                    />
                    <div className="h5">America</div>
                  </div>
                  <div className="info">
                    We are actively looking for partners in the{" "}
                    <span className="text-success">United States, Dominican Republic, Canada, and Mexico</span>.
                  </div>
                </div>
              </Col>

              <Col md="4">
                <div className="partner-country">
                  <div className="place">
                    <img
                      src="/images/partners/europe.svg"
                      alt="Europe"
                      className="mr-2 img-fluid"
                      width="60"
                      height="auto"
                    />
                    <div className="h5">Europe/Middle East/Africa (EMEA)</div>
                  </div>
                  <div className="info">
                    We are actively looking for partners in the{" "}
                    <span className="text-teal">United Kingdom, South Africa, Kenya, and UAE (Dubai)</span>.
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="partner-country">
                  <div className="place">
                    <img
                      src="/images/partners/asia.svg"
                      alt="Asia"
                      className="mr-2 img-fluid"
                      width="41"
                      height="auto"
                    />
                    <div className="h5">Asia/Pacific</div>
                  </div>
                  <div className="info">
                    We are actively looking for partners in{" "}
                    <span className="text-orange">Nepal, India, Bangladesh, Pakistan, and Japan</span>.
                  </div>
                </div>

                <div className="cloud--walk">
                  <div className="text">
                    Reseller Partner in <b>Sri Lanka</b>
                  </div>

                  <a href="https://cloudwa.lk/" target="_blank">
                    <img src="/images/partners/cloudwalk.svg" alt="Cloudwalk" width="35" height="auto" />
                  </a>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* School Partners */}
        <PartnerSlider />
        {/* School Partners */}

        {/* Reseller Benefits */}
        <section id="reseller-benefits" className="py-md-4">
          <article className="container">
            <Row className="align-items-center mt-4 justify-content-between">
              <Col sm={12} className="text-center">
                <div className="mb-4 title">Reseller Benefits</div>
              </Col>
            </Row>

            <Row className="pb-4">
              <Col md="3" xs="6" className="my-4">
                <Card>
                  <Card.Body className="text-center">
                    <div className="ai-image">
                      <img
                        src="/images/partners/high-earn.svg"
                        alt="High Earning Potential"
                        className="img-fluid"
                        width="56"
                        height="auto"
                      />
                    </div>
                    <div className="my-3 h4 font-weight-semibold">High Earning Potential</div>
                    <div className="h5"></div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="3" xs="6" className="my-4">
                <Card>
                  <Card.Body className="text-center">
                    <div className="ai-image">
                      <img
                        src="/images/partners/scaling.svg"
                        alt="Scaling Compensation"
                        className="img-fluid"
                        width="56"
                        height="auto"
                      />
                    </div>
                    <div className="my-3 h4 font-weight-semibold">Scaling Compensation</div>
                    <div className="h5"></div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="3" xs="6" className="my-4">
                <Card>
                  <Card.Body className="text-center">
                    <div className="ai-image">
                      <img
                        src="/images/partners/deal.svg"
                        alt="Milestone Bonuses"
                        className="img-fluid"
                        width="56"
                        height="auto"
                      />
                    </div>
                    <div className="my-3 h4 font-weight-semibold">Milestone Bonuses</div>
                    <div className="h5"></div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md="3" xs="6" className="my-4">
                <Card>
                  <Card.Body className="text-center">
                    <div className="ai-image">
                      <img
                        src="/images/partners/support.svg"
                        alt="Support From Our Nepal and US-based Teams"
                        className="img-fluid"
                        width="56"
                        height="auto"
                      />
                    </div>
                    <div className="my-3 h4 font-weight-semibold">Support From Our Nepal and US-based Teams</div>
                    <div className="h5"></div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg="12" className="my-4 text-center">
                <a
                  href="https://docs.google.com/forms/d/1tyt1ETTIJu4tk6hARl4pPggd-fYTmUL6H7pXFaEDXOk/viewform?edit_requested=true"
                  target="_blank"
                  className="btn btn-warning btn-xl"
                  id="apply-now__middle"
                >
                  APPLY NOW
                </a>
              </Col>
            </Row>
          </article>
        </section>
        {/* Reseller Benefits */}

        {/* Meet our Blended Learning Platform */}
        <section id="blended-learning-platform" className="bg-light-green py-md-4 overflow-hidden">
          <article className="container">
            <Row className="text-center mt-4 ">
              <Col sm={12}>
                <div className="mb-4 title">Meet our Blended Learning Platform</div>
              </Col>

              <Col md="12">
                <div className="h4 mt-4 text-black font-weight-normal">
                  Fuse Classroom gives instructors and students an AI-enabled platform for a new type of learning
                  experience. Embedded in Fuse Classroom are AI features that help you assess student progress,
                  recommend study material, automatically answer student questions, and more.
                </div>
              </Col>

              <Col md={12}>
                <div className="mt-4">
                  <img
                    src="/images/partners/app2x.webp"
                    className="img-fluid"
                    alt="landing"
                    width="1120"
                    height="auto"
                  />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* End Meet our Blended Learning Platform */}

        {/* Online Classroom */}
        <section className="py-md-5 my-lg-5" id="online-classroom">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/platform/lms/lms-main.webp"
                  alt="Online Classroom"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Online Classroom</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Jumpstart your digital school experience with an online classroom that gives students a new and
                    exciting way to learn. Continue reading for more information about the types of tools and features
                    included.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Classroom end */}

        {/* Online Live */}
        <section className="py-md-5 bg-80 right" id="online-live">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-4">
                <img
                  src="/images/platform/onlinelive/online-live.webp"
                  alt="Online Live"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Online Live</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Offer live online classes in a matter of minutes with our Zoom integrated platform equipped with the
                    best tools instructors need.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Live end */}

        {/* Student Information System (SIS) */}
        <section className="py-md-5 my-lg-5" id="sis">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/platform/sis/sis.webp"
                  alt="Student Information System (SIS)"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Student Information System (SIS)</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    AI-powered Student Information System tracks academic and student information in one centralized
                    location. See the features below for a full list of information tracked by the SIS.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Student Information System (SIS) end */}

        {/* Online Exams */}
        <section className="py-md-5 bg-80 right" id="online-exams">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-5">
                <img
                  src="/images/platform/online-exams/online-exams.webp"
                  alt="Online Exams"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>

              <Col md={{ span: 5, order: 1 }}>
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Online Exams</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    The Online Exams feature gives students access to a library of study materials, such as digital
                    flashcards and gamified exams. These study materials ensure students are prepared for their quizzes,
                    assignments, and exams.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Exams end */}

        {/* Community */}
        <section className="py-md-5 mt-lg-5" id="community">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/platform/community/community.webp"
                  alt="community"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Community</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Extend your school’s community online. Fuse Classroom helps create a safe online community for
                    students and other faculty.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Community end */}

        {/* School Management (ERP) */}
        <section className="py-md-5 bg-80 right" id="erp">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-5">
                <img
                  src="/images/platform/erp/erp.webp"
                  alt="School Management (ERP)"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>

              <Col md={{ span: 5, order: 1 }}>
                <div className="py-md-4">
                  <h2 className="font-weight-bold">School Management (ERP)</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    School Management Systems is one AI platform that provide effective and scalable operations.
                    Continue reading to see the different departmental dashboards that can be included in your School
                    Management system.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* School Management (ERP) end */}

        {/* Admission */}
        <section className="pt-md-4" id="community">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/partners/admission.webp"
                  alt="Admission"
                  className="img-fluid"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Admission</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Greatly reduce the operational overhead for the admissions process, from tracking applications to
                    collecting payments and sending final acceptance letters.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Admission end */}

        {/* call to action */}
        <section className="pb-5">
          <article className="container">
            <Row className="">
              <Col lg="12" className="text-center">
                <a
                  href="https://docs.google.com/forms/d/1tyt1ETTIJu4tk6hARl4pPggd-fYTmUL6H7pXFaEDXOk/viewform?edit_requested=true"
                  target="_blank"
                  className="btn btn-warning btn-xl"
                  id="apply-now__bottom"
                >
                  APPLY NOW
                </a>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default Reseller
